import React from "react";
import { v4 as uuidv4 } from "uuid";
import {
  Checkbox,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
} from "@mui/material";

const CustomTableBody = ({
  page,
  limit,
  columns,
  data,
  selection,
  showNumber,
  isSelected,
  loading,
  onSelectRow,
  onRowClick,
}) => {
  const handleRowClick = (row) => {
    if (onRowClick) onRowClick(row);
  };

  return (
    <TableBody>
      {loading ? (
        <TableRow className="body-row">
          <TableCell>
            <CircularProgress />
          </TableCell>
        </TableRow>
      ) : (
        data.map((rowData, index) => (
          <TableRow
            key={uuidv4()}
            className="body-row"
            onClick={() => handleRowClick(rowData)}
            sx={{ ...(onRowClick ? { cursor: "pointer" } : {}) }}
          >
            {selection && (
              <TableCell
                className="body-cell"
                padding="checkbox"
                align="center"
              >
                <Checkbox
                  color="primary"
                  checked={isSelected(rowData.id)}
                  onChange={onSelectRow(rowData.id)}
                />
              </TableCell>
            )}
            {showNumber && (
              <TableCell className="body-cell" align="center">
                {(page - 1) * limit + index + 1}
              </TableCell>
            )}
            {columns.map((column) => (
              <TableCell
                className="body-cell"
                align={column.align}
                key={uuidv4()}
              >
                {column.render ? column.render(rowData) : rowData[column.field]}
              </TableCell>
            ))}
          </TableRow>
        ))
      )}
    </TableBody>
  );
};

export default CustomTableBody;
