import React from "react";
import { Box } from "@mui/material";

import Header from "./Header";
import Menu from "./Menu";

const Desktop = ({ style, openMiniSidebar }) => {
  return (
    <Box flex="none" sx={style} zIndex={1000}>
      <Box position="fixed" top={0} left={0} height="100vh" width="inherit">
        <Header openMiniSidebar={openMiniSidebar} />
        <Menu openMiniSidebar={openMiniSidebar} />
      </Box>
    </Box>
  );
};

export default Desktop;
