import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import mapboxgl from "mapbox-gl";
import { useEffect, useRef } from "react";

import "mapbox-gl/dist/mapbox-gl.css";

import Dialog from "../../components/Dialog";
import Table from "../../components/Table";

const TimesheetDetailDialog = ({ open, handleClose, timesheet }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("time"),
      align: "left",
      render: (row) => dayjs(row.time).format("HH:mm:ss"),
    },
    {
      title: t("location"),
      align: "left",
      render: (row) => (row.name ? row.name : "-"),
    },
  ];

  return (
    <Dialog
      title={t("timesheet")}
      subTitle={
        timesheet.date ? dayjs(timesheet.date).format("DD-MM-YYYY") : ""
      }
      maxWidth="lg"
      open={open}
      onClose={handleClose}
    >
      <DialogContent>
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="flex-start"
        >
          <MapboxWrapper
            geometry={timesheet?.detail?.routes[0]?.geometry || null}
            waypoints={timesheet?.detail?.waypoints || null}
          />
          <Box minWidth="350px">
            <Typography variant="h6" marginBottom={1}>
              {t("checkInList")}
            </Typography>
            <Table
              data={timesheet?.detail?.waypoints || []}
              columns={columns}
              disablePagination={true}
              page={1}
              showNumber={true}
            />
          </Box>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="outlined" color="secondary" onClick={handleClose}>
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const MapboxWrapper = ({ geometry, waypoints }) => {
  const mapContainerRef = useRef(null);
  const mapRef = useRef(null);

  const getCenter = (coordinates) => {
    const numberOfCoordinates = coordinates.length;
    return coordinates
      .reduce(
        (acc, coordinate) => {
          acc[0] += coordinate[0];
          acc[1] += coordinate[1];
          return acc;
        },
        [0, 0]
      )
      .map((item) => item / numberOfCoordinates);
  };

  useEffect(() => {
    if (geometry && mapContainerRef.current) {
      mapboxgl.accessToken =
        "pk.eyJ1IjoidmVsYWZvb2RzIiwiYSI6ImNtMG5kcmd2OTBjaGsyd3B6b2J3eDJyOTQifQ.DGfa3hHt0S40CwdW1kO9nw";

      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: getCenter(geometry.coordinates),
        zoom: 12,
      });

      mapRef.current.on("load", () => {
        mapRef.current.addSource("route", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry,
          },
        });

        mapRef.current.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#1976d2",
            "line-width": 6,
          },
        });
      });

      for (let [index, waypoint] of waypoints.entries()) {
        new mapboxgl.Marker({ color: "#D2001A" })
          .setLngLat(waypoint.location)
          .setPopup(
            new mapboxgl.Popup({ offset: 10 }).setHTML(
              `<h3>${index + 1}. ${dayjs(waypoint.time).format(
                "HH:mm:ss"
              )}</h3><p>${waypoint.name || "-"}</p>`
            )
          )
          .addTo(mapRef.current);
      }
    }
  }, [geometry, waypoints]);

  return (
    <Box
      style={{ width: "810px", height: "540px" }}
      ref={mapContainerRef}
      className="map-container"
    ></Box>
  );
};

export default TimesheetDetailDialog;
