const APPLIED_OBJECT_TYPE = {
  REGION: "region",
  PROVINCE: "province",
  DISTRIBUTOR: "distributor",
  STORE: "store",
};

const DISCOUNT_TYPE = {
  PRODUCT: "PRODUCT",
  MONEY: "MONEY",
};

const DISCOUNT_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
};

const APPLIED_OBJECT_MAPPING = {
  [APPLIED_OBJECT_TYPE.REGION]: "Vùng",
  [APPLIED_OBJECT_TYPE.PROVINCE]: "Tỉnh",
  [APPLIED_OBJECT_TYPE.DISTRIBUTOR]: "Nhà phân phối",
  [APPLIED_OBJECT_TYPE.STORE]: "Cửa hàng",
};

const DISCOUNT_TYPE_MAPPING = {
  [DISCOUNT_TYPE.PRODUCT]: "Mua sản phẩm",
  [DISCOUNT_TYPE.MONEY]: "Tổng giá trị đơn hàng",
};

const VALUE_DISCOUNT_TYPE_MAPPING = {
  [DISCOUNT_TYPE.PRODUCT]: "Sản phẩm",
  [DISCOUNT_TYPE.MONEY]: "Giảm tiền",
};

export {
  APPLIED_OBJECT_TYPE,
  DISCOUNT_TYPE,
  DISCOUNT_STATUS,
  APPLIED_OBJECT_MAPPING,
  DISCOUNT_TYPE_MAPPING,
  VALUE_DISCOUNT_TYPE_MAPPING,
};
