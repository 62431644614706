import { COLOR } from "./color";

const BORDER_RADIUS = {
  popup: "24px 24px 0px 0px",
  container: "4px",
};

const BOX_SHADOW = "0px 2px 16px rgba(0, 0, 0, 0.32)";

const BOX_SHADOW_CARD =
  "0px 4px 16px 0px rgba(0, 0, 0, 0.16), 0px 0px 4px 0px rgba(0, 0, 0, 0.08)";

const BORDER = `1px solid ${COLOR.pastelBlue[100]}`;

const TOP_BAR_HEIGHT = 60;

const FULL_SIDEBAR_WIDTH = 260;
const MINI_SIDEBAR_WIDTH = 72;

export {
  BORDER_RADIUS,
  BOX_SHADOW,
  TOP_BAR_HEIGHT,
  FULL_SIDEBAR_WIDTH,
  MINI_SIDEBAR_WIDTH,
  BOX_SHADOW_CARD,
  BORDER,
};
