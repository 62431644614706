import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const StyledLogin = styled(Box)`
  background-color: rgb(250, 250, 251);
  width: 100%;
  min-height: 100vh;

  .container {
    width: 400px;
    padding: 20px;
  }
`;
