import { useHistory } from "react-router-dom";

import { Box, Stack, Typography } from "@mui/material";

const PageTitle = ({ title, breadcrumbs }) => {
  const history = useHistory();

  if (title)
    return (
      <Box pb={3} pt={1}>
        <Typography variant="h5" fontWeight={500}>
          {title}
        </Typography>
      </Box>
    );

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      spacing={2}
      pb={3}
      pt={1}
    >
      {breadcrumbs.map((breadcrumb, index, currArray) => {
        return (
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            key={breadcrumb.title}
          >
            <Typography
              sx={{ cursor: "pointer" }}
              variant="h6"
              onClick={() =>
                breadcrumb.path ? history.push(breadcrumb.path) : null
              }
            >
              {breadcrumb.title}
            </Typography>
            {index !== currArray.length - 1 && <Typography>/</Typography>}
          </Stack>
        );
      })}
    </Stack>
  );
};

export default PageTitle;
