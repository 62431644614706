import React from "react";
import Drawer from "@mui/material/Drawer";

import Header from "./Header";
import Menu from "./Menu";

const Mobile = (props) => {
  const { ...other } = props;
  return (
    <Drawer variant="permanent" {...other}>
      <Header openMiniSidebar={false} />
      <Menu openMiniSidebar={false} onClose={props.onClose} />
    </Drawer>
  );
};

export default Mobile;
