import { useMediaQuery } from "@mui/material";

import MobileSidebar from "./Mobile";
import DesktopSidebar from "./Desktop";
import { FULL_SIDEBAR_WIDTH, MINI_SIDEBAR_WIDTH } from "../../styles/config";
import { COLOR } from "../../styles/color";

const Sidebar = ({
  openMiniSidebar,
  openMobileSidebar,
  onMobileSidebarToggle,
}) => {
  const isSmUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const drawerWidth = !openMiniSidebar
    ? FULL_SIDEBAR_WIDTH
    : MINI_SIDEBAR_WIDTH;
  return (
    <>
      {!isSmUp && (
        <MobileSidebar
          PaperProps={{ style: { width: FULL_SIDEBAR_WIDTH } }}
          variant="temporary"
          open={openMobileSidebar}
          onClose={onMobileSidebarToggle}
        />
      )}

      <DesktopSidebar
        style={{
          display: { md: "block", sm: "none", xs: "none" },
          width: drawerWidth,
          transition: "width .3s ease",
          borderRight: `1px solid ${COLOR.black[8]}`,
        }}
        openMiniSidebar={openMiniSidebar}
      />
    </>
  );
};

export default Sidebar;
