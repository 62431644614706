import React from "react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import { BORDER_RADIUS, TOP_BAR_HEIGHT } from "../../styles/config";

const Header = ({ openMiniSidebar }) => {
  const history = useHistory();
  const logo = openMiniSidebar ? "/vela-logo-collapse.jpg" : "/vela-logo.png";
  const heightLogo = openMiniSidebar ? 44 : TOP_BAR_HEIGHT;

  return (
    <>
      <Box
        paddingY={openMiniSidebar ? 1 : 0}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={logo}
          alt="aihub"
          height={heightLogo}
          onClick={() => history.push("/")}
          style={{
            cursor: "pointer",
            borderRadius: openMiniSidebar ? BORDER_RADIUS.container : 0,
          }}
        />
      </Box>
    </>
  );
};

export default Header;
