/* eslint-disable consistent-return */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import camelcase from "camelcase";
import { toast } from "react-toastify";
import { Box, CircularProgress, Grid, Tab, Tabs } from "@mui/material";

import apis from "../../apis";
import { ROLE_TYPE } from "../../constants";
import RoleList from "./RoleList";
import PermissionList from "./PermissionList";
import { StyledRole } from "./index.style";

const Role = () => {
  const { t } = useTranslation();

  const [tab, setTab] = useState(ROLE_TYPE.MINI_APP);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [groupPermissions, setGroupPermissions] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [selectedRole, setSelectedRole] = useState({});

  const fetchRoles = async (firstFetch = false) => {
    try {
      const res = await apis.role.getRoles();
      if (!res) throw new Error();
      const { result = [] } = res;
      setRoles(result);
      if (firstFetch) {
        const firstRole = result.find((gp) => gp.type === tab);
        setSelectedRole(firstRole ? firstRole : {});
      }
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchPermissions = async () => {
    try {
      const res = await apis.permission.getPermissions();
      if (!res) throw new Error();
      setPermissions(res?.result || []);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchGroupPermissions = async () => {
    try {
      const res = await apis.groupPermission.getGroupPermissions();
      if (!res) throw new Error();
      setGroupPermissions(res?.result || []);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const init = async () => {
    setLoading(true);
    await Promise.all([
      fetchRoles(true),
      fetchPermissions(),
      fetchGroupPermissions(),
    ]);
    setLoading(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    const firstRole = roles.find((gp) => gp.type === newValue);

    setSelectedRole(firstRole ? firstRole : {});
  };

  useEffect(() => {
    init();
  }, []);

  if (loading) {
    return (
      <Box p={2}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <StyledRole>
      <Box className="content-container">
        <Grid container>
          <Grid item xs={3}>
            <Box className="list-container">
              <Box className="list">
                <Tabs
                  value={tab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChangeTab}
                  aria-label="disabled tabs example"
                  className="tabs"
                >
                  {Object.values(ROLE_TYPE).map((value) => (
                    <Tab
                      value={value}
                      label={t(camelcase(value))}
                      key={value}
                      className="tab"
                    />
                  ))}
                </Tabs>
                <RoleList
                  roleType={tab}
                  roles={roles.filter((el) => el.type === tab)}
                  selectedRole={selectedRole}
                  setSelectedRole={setSelectedRole}
                  onReload={fetchRoles}
                />
              </Box>
            </Box>
          </Grid>
          {!!Object.keys(selectedRole).length && (
            <Grid item xs={9}>
              <PermissionList
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
                groupPermissions={groupPermissions}
                permissions={permissions}
                reload={fetchRoles}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </StyledRole>
  );
};

export default Role;
