import { SERVICE } from "../constants";
import api from "./api";

export const getStores = async (params) => {
  const res = await api({
    method: "GET",
    url: `/stores`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};
