import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import Dialog from "../../components/Dialog";
import { validateRequired as checkRequired } from "../../utils/validate";
import { COLOR } from "../../styles/color";

const INIT_GROUP_PERMISSION_ERROR = {
  name: "",
};

const GroupPermissionDialog = ({
  open,
  handleClose,
  dialogType,
  groupPermissionType,
  groupPermission,
  setGroupPermission,
  reload,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorGroupPermission, setErrorGroupPermission] = useState(
    INIT_GROUP_PERMISSION_ERROR
  );

  const handleCloseDialog = () => {
    setErrorGroupPermission(INIT_GROUP_PERMISSION_ERROR);
    handleClose();
  };

  const handChangeGroupPermission = (event, field) => {
    const value = event.target.value;
    setGroupPermission((prev) => ({ ...prev, [field]: value }));
    setErrorGroupPermission((prev) => ({ ...prev, [field]: false }));
  };

  const validateRequired = (field) => {
    if (!checkRequired(groupPermission[field])) {
      setErrorGroupPermission((prev) => ({
        ...prev,
        [field]: t("fieldRequired"),
      }));
      return false;
    }
    return true;
  };

  const validate = () => validateRequired("name");

  const handleCreateGroupPermission = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      await apis.groupPermission.createGroupPermission({
        ...groupPermission,
        type: groupPermissionType,
      });
      handleCloseDialog();
      reload();
      toast.success(t("addGroupPermissionSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const handleUpdateGroupPermission = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      await apis.groupPermission.updateGroupPermission(groupPermission.id, {
        name: groupPermission.name,
      });
      handleCloseDialog();
      reload();
      toast.success(t("editGroupPermissionSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  return (
    <Dialog
      title={
        dialogType === "create"
          ? t("addGroupPermission")
          : t("editGroupPermission")
      }
      maxWidth="xs"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {groupPermission && (
          <Box mb={3}>
            <Typography fontWeight={500} mb={1}>
              {t("groupPermissionName")}{" "}
              <span style={{ color: COLOR.error.base }}>*</span>:
            </Typography>
            <TextField
              helperText={errorGroupPermission.name}
              error={!!errorGroupPermission.name}
              value={groupPermission.name}
              size="small"
              fullWidth
              onChange={(event) => handChangeGroupPermission(event, "name")}
              onBlur={() => validateRequired("name")}
              placeholder={t("groupPermissionName")}
            />
          </Box>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        {dialogType === "create" ? (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleCreateGroupPermission}
          >
            {t("addNew")}
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdateGroupPermission}
          >
            {t("save")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default GroupPermissionDialog;
