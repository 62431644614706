import { Box, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { COLOR, TRANSPARENT_COLOR } from "../../styles/color";

export const StyledCustomDatePickerRange = styled(Box)`
  display: flex;

  .date-range {
    display: flex;
    background-color: ${COLOR.white[100]};
    border: 1px solid ${COLOR.black[16]};
    border-radius: 8px;
    height: 36px;
  }

  .input-wrapper {
    display: flex;
    gap: 12px;
    align-items: center;
    margin: 0 12px;
  }

  .icon {
    cursor: pointer;
  }

  .light-color {
    color: ${COLOR.light};
  }

  .MuiOutlinedInput-root {
    width: 140px;

    input {
      padding: 6px 0;
      height: fit-content;
      color: ${COLOR.dark};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  color: ${(props) => (props.active ? COLOR.white : COLOR.text)};
  background-color: ${(props) => (props.active ? COLOR.primary : COLOR.white)};

  &:hover {
    color: ${(props) => (props.active ? COLOR.white : COLOR.text)};
    background-color: ${(props) =>
      props.active ? COLOR.primary : TRANSPARENT_COLOR.primary};
  }
`;
