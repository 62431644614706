import * as auth from "./auth";
import * as groupPermission from "./groupPermission";
import * as permission from "./permission";
import * as role from "./role";
import * as user from "./user";
import * as qr from "./qr";
import * as region from "./region";
import * as address from "./address";
import * as store from "./store";
import * as timesheet from "./timesheet";
import * as discount from "./discount";
import * as product from "./product";
import * as order from "./order";

const allApi = {
  auth,
  groupPermission,
  permission,
  role,
  user,
  qr,
  region,
  address,
  store,
  timesheet,
  discount,
  product,
  order,
};

export default allApi;
