import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import apis from "../../apis";
import { COLOR } from "../../styles/color";
import { PAGE_TYPE } from "../../constants";
import { validateRequired as checkRequired } from "../../utils/validate";

const INIT_ERROR = {
  region: "",
};

const SupervisorConfig = ({ data, setData, setIsFetchQr }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const [error, setError] = useState(INIT_ERROR);

  const renderDistributorOptions = (id) => {
    const distributor = distributors.find((d) => d.id === id);
    if (!distributor) return "";
    return `${distributor.name} - ${distributor.phoneNumber}`;
  };

  const handleChangeRegion = (newValue) => {
    setData((prev) => ({ ...prev, region: newValue, distributorIds: [] }));
    setError((prev) => ({ ...prev, region: "" }));
    if (!newValue) {
      setDistributors([]);
    }
  };

  const handleChangeDistributorIds = (newValue) => {
    setData((prev) => ({
      ...prev,
      distributorIds: newValue,
    }));
  };

  const validateRequired = (field) => {
    if (!checkRequired(data[field])) {
      setError((prev) => ({
        ...prev,
        [field]: t("fieldRequired"),
      }));
      return false;
    }
    return true;
  };

  const validate = () => validateRequired("region");

  const handleCreateQr = () => {
    if (!validate()) return;
    setIsFetchQr(true);
  };

  const getRegions = async () => {
    setLoading(true);
    try {
      const res = await apis.region.getRegions();
      setRegions(res.result);
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const getDistributors = async () => {
    try {
      const res = await apis.user.getUsers({
        pageType: PAGE_TYPE.DISTRIBUTOR,
        region: data.region,
      });
      setDistributors(res.result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  useEffect(() => {
    getRegions();
  }, []);

  useEffect(() => {
    if (data.region) getDistributors();
  }, [data.region]);

  if (loading)
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="400px"
      >
        <CircularProgress />
      </Stack>
    );

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      spacing={2}
      height="400px"
    >
      <Box>
        <Box mb={3}>
          <Typography fontWeight={500} mb={1}>
            {t("chooseRegion")}{" "}
            <span style={{ color: COLOR.error.base }}>*</span>:
          </Typography>
          <Autocomplete
            size="small"
            value={data.region || null}
            options={regions.map((region) => region.name)}
            getOptionLabel={(option) => `${t("region")} ${option}`}
            filterSelectedOptions
            onChange={(event, newValue) => handleChangeRegion(newValue)}
            onBlur={() => validateRequired("region")}
            renderOption={(props, key) => (
              <MenuItem value={key} {...props}>
                {`${t("region")} ${key}`}
              </MenuItem>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!error.region}
                helperText={error.region}
                fullWidth
              />
            )}
          />
        </Box>
        <Box>
          <Typography fontWeight={500} mb={1}>
            {t("chooseDistributors")}:
          </Typography>
          <Autocomplete
            multiple
            size="small"
            value={data.distributorIds}
            options={distributors
              .filter((distributor) => !distributor.supervisorId)
              .map((distributor) => distributor.id)}
            getOptionLabel={(option) => renderDistributorOptions(option)}
            filterSelectedOptions
            onChange={(event, newValue) => handleChangeDistributorIds(newValue)}
            renderOption={(props, key) => (
              <MenuItem value={key} {...props}>
                {renderDistributorOptions(key)}
              </MenuItem>
            )}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </Box>
      </Box>
      <Stack justifyContent="center" alignItems="center">
        <Button variant="outlined" onClick={handleCreateQr}>
          {t("generateQr")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default SupervisorConfig;
