import React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import { StyledPopup } from "./index.style";

const Popup = (props) => {
  const { t } = useTranslation();
  const {
    open,
    onOk,
    onClose,
    title = t("note"),
    okMessage = t("confirm"),
    cancelMessage = t("cancel"),
    content = t("cannotUndo"),
    iconComponent,
  } = props;

  const handleOk = (e) => {
    onOk(e);
    onClose(e);
  };

  return (
    <StyledPopup
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        display="flex"
        alignItems="center"
        gap={1}
        className="dialog-title pd-0 mb-16"
      >
        {iconComponent}
        <DialogContentText
          id="alert-dialog-description"
          className="title"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </DialogTitle>
      <DialogContent className="dialog-content pd-0 mb-16">
        <DialogContentText
          id="alert-dialog-description"
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </DialogContent>
      <DialogActions className="pd-0">
        <Button
          onClick={onClose}
          variant="outlined"
          style={{ minWidth: "100px" }}
        >
          {cancelMessage}
        </Button>
        <Button
          onClick={handleOk}
          variant="contained"
          style={{ minWidth: "100px" }}
        >
          {okMessage}
        </Button>
      </DialogActions>
    </StyledPopup>
  );
};

export default Popup;
