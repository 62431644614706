import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Autorenew, Search } from "@mui/icons-material";
import dayjs from "dayjs";

import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import { INITIAL_PAGING, PAGE_TYPE } from "../../constants";
import apis from "../../apis";
import UserList from "./UserList";
import useSearchParams from "../../hooks/useSearchParams";
import debounce from "../../utils/debounce";
import DatePickerRange from "../../components/PickDateRange";

const INIT_FILTER = {
  search: "",
  startDate: dayjs().startOf("month"),
  endDate: dayjs().endOf("month"),
};

const Timesheet = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [filter, setFilter] = useState(INIT_FILTER);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const onChangeSearch = (value) => {
    setPaging((prev) => ({ ...prev, page: INITIAL_PAGING.page }));
    addParams({ search: value, page: INITIAL_PAGING.page });
  };

  const handleChangeSearch = (event) => {
    const { value } = event.target;
    setFilter((prev) => ({ ...prev, search: value }));
    debounce(onChangeSearch, 1000)(value);
  };

  const handleChangeDate = (newValue) => {
    setFilter((prev) => ({
      ...prev,
      startDate: newValue[0],
      endDate: newValue[1],
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      startDate: dayjs(newValue[0]).format("YYYY-MM-DD"),
      endDate: dayjs(newValue[1]).format("YYYY-MM-DD"),
    });
  };

  const handleRefresh = () => {
    setFilter((prev) => ({
      ...prev,
      startDate: INIT_FILTER.startDate,
      endDate: INIT_FILTER.endDate,
    }));
    setPaging(INITIAL_PAGING);
    addParams({
      page: INITIAL_PAGING.page,
      startDate: dayjs(INIT_FILTER.startDate).format("YYYY-MM-DD"),
      endDate: dayjs(INIT_FILTER.endDate).format("YYYY-MM-DD"),
    });
  };

  const getTimesheetStats = async ({ userIds, startDate, endDate }) => {
    try {
      const { result } = await apis.timesheet.getTimesheetStats({
        userIds,
        startDate,
        endDate,
      });
      return result;
    } catch (error) {
      toast.error(t(error.message));
      return [];
    }
  };

  const fetchUsers = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
        search: newFilter.search || undefined,
        pageType: PAGE_TYPE.TIMESHEET,
      };

      const { result } = await apis.user.getUsers(condition);
      if (result.users.length) {
        const userIds = result.users.map((user) => user.id);
        const timesheetStats = await getTimesheetStats({
          userIds: userIds.join(),
          startDate: newFilter.startDate,
          endDate: newFilter.endDate,
        });
        setUsers(
          result.users.map((user) => {
            const timesheetStat = timesheetStats.find(
              (stat) => stat.userId === user.id
            );
            return {
              ...user,
              totalTraveledDistance: timesheetStat?.traveledDistance || 0,
            };
          })
        );
      } else setUsers(result.users);

      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const {
      page = INITIAL_PAGING.page,
      search = INIT_FILTER.search,
      startDate = dayjs(INIT_FILTER.startDate).format("YYYY-MM-DD"),
      endDate = dayjs(INIT_FILTER.endDate).format("YYYY-MM-DD"),
    } = searchParams;
    setFilter({ search, startDate, endDate });
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchUsers({ page: parseInt(page, 10), search, startDate, endDate });
  }, [location.search]);

  return (
    <>
      <PageTitle title={t("user")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={4}>
            <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("userSearch")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display="flex">
              <DatePickerRange
                value={[filter.startDate, filter.endDate]}
                onChange={handleChangeDate}
              />
              <Tooltip title={t("refresh")}>
                <IconButton aria-label="refresh" onClick={handleRefresh}>
                  <Autorenew />
                </IconButton>
              </Tooltip>
            </Box>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              {/* <Button
                variant="contained"
                startIcon={<Add />}
                // onClick={handleOpenQrDialog}
              >
                {t("addDistributor")}
              </Button> */}
            </Stack>
          </Grid>
        </Grid>
        <UserList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          users={users}
        />
      </MainCard>
    </>
  );
};

export default Timesheet;
