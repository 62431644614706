import { useMemo } from "react";

// material-ui
import { StyledEngineProvider } from "@mui/material";
import { alpha, createTheme, ThemeProvider } from "@mui/material/styles";

// project import
import Palette from "./palette";
import Typography from "./typography";
import componentsOverride from "./overrides";
import { TOP_BAR_HEIGHT } from "../config";

// ==============================|| DEFAULT THEME - MAIN  ||============================== //

const ThemeCustomization = ({ children }) => {
  const themeCustomPalette = Palette();
  const themeTypography = Typography(`'SF Pro Rounded', sans-serif`);

  const themeOptions = useMemo(
    () => ({
      breakpoints: {
        values: {
          xs: 0,
          sm: 768,
          md: 1024,
          lg: 1266,
          xl: 1440,
        },
      },
      direction: "ltr",
      mixins: {
        toolbar: { minHeight: TOP_BAR_HEIGHT },
      },
      shape: { borderRadius: 8 },
      palette: themeCustomPalette,
      customShadows: {
        button: "0 2px #0000000b",
        text: "0 -1px 0 rgb(0 0 0 / 12%)",
        z1: `0px 2px 8px ${alpha("#000000", 0.15)}`,
      },
      typography: themeTypography,
    }),
    [themeCustomPalette, themeTypography]
  );

  const themes = createTheme(themeOptions);
  themes.components = componentsOverride(themes);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default ThemeCustomization;
