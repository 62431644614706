import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";
import { COLOR } from "../../styles/color";

export const StyledPopup = styled(Dialog)`
  .MuiDialog-paper {
    min-width: ${(props) => props.width || "512px"};
    padding: 16px 24px !important;
  }

  .pd-0 {
    padding: 0px !important;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .title {
    color: ${COLOR.text.primary};
    font-size: 20px;
  }
`;
