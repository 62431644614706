import React from "react";
import { useTranslation } from "react-i18next";
import { Pagination, Typography } from "@mui/material";

import { StyledTableFooter } from "./index.style";

const CustomTableFooter = ({ total, page, limit, onChangePage }) => {
  const { t } = useTranslation();

  const handleChangePage = (event, newPage) => {
    onChangePage(newPage);
  };

  const from = (page - 1) * limit + 1;
  const to = page * limit < total ? page * limit : total;

  return (
    <StyledTableFooter>
      <Typography className="footer-label">
        {t("paginationInfo", { from, to, total })}
      </Typography>
      {total > limit && (
        <Pagination
          count={Math.ceil(total / limit)}
          page={page}
          color="primary"
          onChange={handleChangePage}
        />
      )}
    </StyledTableFooter>
  );
};

export default CustomTableFooter;
