import { InputBase } from "@mui/material";

const NumberInput = ({
  value,
  onChangeValue = () => {},
  minValue,
  sx = {},
  step = 1,
}) => {
  const handleChangeValue = (e) => {
    if (typeof minValue === "number" && e.target.value < minValue) return;
    onChangeValue(e.target.value);
  };

  const handleBlur = () => {
    if (typeof minValue === "number")
      onChangeValue(Math.max(Math.floor(minValue), Math.floor(value)));
    else onChangeValue(Math.floor(value));
  };

  return (
    <InputBase
      type="number"
      value={value}
      onChange={handleChangeValue}
      onBlur={handleBlur}
      inputProps={{
        step,
      }}
      sx={{
        border: `1px solid #E0E0E0`,
        borderRadius: "8px",
        padding: "3px 8px",
        textAlign: "center",
        ...sx,
      }}
    />
  );
};

export default NumberInput;
