import { SERVICE } from "../constants";
import api from "./api";

export const getRoles = async (params) => {
  const res = await api({
    method: "GET",
    url: `/roles`,
    params,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

export const createRole = async (data) => {
  const res = await api({
    method: "POST",
    url: `/roles`,
    data,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

export const updateRole = async (roleId, data) => {
  const res = await api({
    method: "PUT",
    url: `/roles/${roleId}`,
    data,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

export const deleteRole = async (roleId) => {
  const res = await api({
    method: "DELETE",
    url: `/roles/${roleId}`,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

export const assignPermissions = async (roleId, data) => {
  const res = await api({
    method: "PUT",
    url: `/roles/${roleId}/assign-permissions`,
    data,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};
