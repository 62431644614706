import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Table from "../../components/Table";
import { COLOR } from "../../styles/color";
import { ORDER_STATUS } from "../../constants/order";

const OrderList = ({ loading, paging, handleChangePage, orders }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("id"),
      align: "left",
      field: "id",
    },
    {
      title: t("store"),
      align: "left",
      render: (row) => row.storeId?.name || "--",
    },
    {
      title: t("orderItems"),
      align: "left",
      render: (row) => (
        <>
          {row.items?.map((item) => (
            <Typography>{`${item.quantity || 0} ${item.name}`}</Typography>
          ))}
        </>
      ),
    },
    {
      title: t("orderPrice"),
      align: "right",
      render: (row) => row.orderPrice?.toLocaleString() || 0,
    },
    {
      title: t("discountPrice"),
      align: "right",
      render: (row) => row.discountPrice?.toLocaleString() || "--",
    },
    {
      title: t("discountItems"),
      align: "right",
      render: (row) => (
        <Typography>
          {row.discountItems
            ?.map((item) => `${item.quantity || 0} ${item.name}`)
            ?.join(", ") || "--"}
        </Typography>
      ),
    },
    {
      title: t("totalPrice"),
      align: "right",
      render: (row) => row.totalPrice?.toLocaleString() || 0,
    },
    {
      title: t("status"),
      align: "center",
      render: (row) => (
        <>
          {row.status === ORDER_STATUS.COMPLETED && (
            <Typography sx={{ color: COLOR.success.base }}>
              {t("completed")}
            </Typography>
          )}
          {row.status === ORDER_STATUS.IN_PROGRESS && (
            <Typography sx={{ color: COLOR.info.base }}>
              {t("in_progress")}
            </Typography>
          )}
          {row.status === ORDER_STATUS.AWAITING_CONFIRMATION && (
            <Typography sx={{ color: COLOR.warning.base }}>
              {t("awaiting_confirmation")}
            </Typography>
          )}
          {row.status === ORDER_STATUS.CANCEL && (
            <Typography sx={{ color: COLOR.error.base }}>
              {t("canceled")}
            </Typography>
          )}
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={orders}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default OrderList;
