import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const roleSlice = createSlice({
  name: "role",
  initialState: initialState,
  reducers: {
    setCurrentRole: (state, action) => {
      return { ...action.payload };
    },
  },
});

export const { setCurrentRole } = roleSlice.actions;

export default roleSlice.reducer;
