import { SERVICE } from "../constants";
import api from "./api";

export const getTimesheetStats = async (params) => {
  const res = await api({
    method: "GET",
    url: `/timesheets/stats`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};

export const getTimesheets = async (params) => {
  const res = await api({
    method: "GET",
    url: `/timesheets`,
    params,
    source: SERVICE.VELA_API,
  });
  return res;
};
