import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import {
  LockOpenOutlined,
  LockOutlined,
  SocialDistance,
} from "@mui/icons-material";

import Table from "../../components/Table";
import { COLOR } from "../../styles/color";
import TransferDistributorDialog from "./TransferDistributorDialog";

const DistributorList = ({
  loading,
  paging,
  handleChangePage,
  distributors,
  handleOpenChangeStatusPopup,
}) => {
  const { t } = useTranslation();

  const [distributor, setDistributor] = useState({});
  const [openTransferDistributorDialog, setOpenTransferDistributorDialog] =
    useState(false);

  const handleOpenTransferDistributorDialog = (row) => {
    setDistributor(row);
    setOpenTransferDistributorDialog(true);
  };

  const handleCloseTransferDistributorDialog = () => {
    setOpenTransferDistributorDialog(false);
    setDistributor({});
  };

  const columns = [
    {
      title: t("fullName"),
      align: "left",
      field: "name",
    },
    {
      title: t("phoneNumber"),
      align: "left",
      field: "phoneNumber",
    },
    {
      title: t("region"),
      align: "left",
      render: (row) => `${t("region")} ${row.region}`,
    },
    {
      title: t("status"),
      align: "center",
      render: (row) => (
        <>
          {row.active ? (
            <Typography sx={{ color: COLOR.success.base }}>
              {t("active")}
            </Typography>
          ) : (
            <Typography sx={{ color: COLOR.error.base }}>
              {t("inactive")}
            </Typography>
          )}
        </>
      ),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("handoverOfWork")}>
              <span>
                <IconButton
                  onClick={() => handleOpenTransferDistributorDialog(row)}
                  color="info"
                >
                  <SocialDistance />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={row.active ? t("lock") : t("unlock")}>
              <span>
                <IconButton
                  onClick={() => handleOpenChangeStatusPopup(row)}
                  color={row.active ? "error" : "success"}
                >
                  {row.active ? <LockOutlined /> : <LockOpenOutlined />}
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={distributors}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
      <TransferDistributorDialog
        open={openTransferDistributorDialog}
        handleClose={handleCloseTransferDistributorDialog}
        distributor={distributor}
      />
    </>
  );
};

export default DistributorList;
