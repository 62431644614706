import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import { BORDER } from "../../styles/config";
import PermissionType from "../Permission/PermissionType";

const PermissionList = ({
  selectedRole,
  setSelectedRole,
  groupPermissions,
  permissions,
  reload,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleSelectPermission = (permission) => {
    let newPermissions = [...selectedRole.permissions];
    if (selectedRole.permissions.map((p) => p.id).includes(permission.id)) {
      newPermissions = newPermissions.filter((el) => el.id !== permission.id);
    } else {
      newPermissions.push(permission);
    }

    setSelectedRole({
      ...selectedRole,
      permissions: newPermissions,
    });
  };

  const handleSave = async () => {
    setLoading(true);
    try {
      await apis.role.assignPermissions(selectedRole.id, {
        permissionIds: selectedRole.permissions.map((p) => p.id),
      });
      reload();
      toast.success(t("assignPermissionsSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  return (
    <>
      <Box
        display="flex"
        paddingX={1}
        height={48}
        alignItems="center"
        gap={1.5}
      >
        <Typography marginRight="auto">{t("permissionList")}</Typography>
        {!selectedRole.isMasterRole && (
          <LoadingButton
            variant="contained"
            loading={loading}
            onClick={handleSave}
          >
            {t("save")}
          </LoadingButton>
        )}
      </Box>
      <Box maxHeight="calc(100vh - 140px)" overflow="auto">
        {groupPermissions
          .filter((gp) => gp.type === selectedRole.type)
          .map((gp) => (
            <Box px={4.5} py={2.5} borderBottom={BORDER} key={gp.id}>
              <Typography mb={1} variant="h6">
                {gp.name}
              </Typography>
              <Grid container spacing={0.5}>
                {permissions
                  .filter((p) => p.groupPermissionId === gp.id)
                  ?.map((permission) => (
                    <Grid key={permission.id} item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={
                              selectedRole.isMasterRole ||
                              selectedRole.permissions
                                .map((sr) => sr.id)
                                .includes(permission.id)
                            }
                            disabled={selectedRole.isMasterRole}
                          />
                        }
                        label={
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            gap={1}
                          >
                            <PermissionType type={permission.type} />
                            <Typography variant="subtitle1">
                              {permission.name}
                            </Typography>
                          </Box>
                        }
                        onChange={() => handleSelectPermission(permission)}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Box>
          ))}
      </Box>
    </>
  );
};

export default PermissionList;
