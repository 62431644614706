import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { validateRequired } from "../utils/validate";

const cleanSearchParams = (searchParams = {}) =>
  Object.keys(searchParams).reduce((acc, key) => {
    if (validateRequired(searchParams[key])) acc[key] = searchParams[key];
    return acc;
  }, {});

const useSearchParams = () => {
  const location = useLocation();
  const history = useHistory();

  const addParams = (params) => {
    const searchParams = queryString.parse(location.search);
    const newSearchParams = cleanSearchParams({ ...searchParams, ...params });
    const searchString = queryString.stringify(newSearchParams);
    history.push({ search: searchString });
  };

  const removeParams = (...params) => {
    const listParamNames = [...params];
    if (!listParamNames) return;
    const searchParams = queryString.parse(location.search);

    listParamNames.forEach((param) => {
      delete searchParams[param];
    });

    const searchString = queryString.stringify(searchParams);
    history.push({ search: searchString });
  };

  const removeAllParams = () => {
    history.push({ search: null });
  };

  const replaceNewParams = (params) => {
    const newSearchParams = cleanSearchParams(params);
    const searchString = queryString.stringify(newSearchParams);
    history.push({ search: searchString });
  };

  const params = queryString.parse(location.search);

  return {
    addParams,
    removeParams,
    removeAllParams,
    replaceNewParams,
    params,
  };
};

export default useSearchParams;
