import { Menu } from "@mui/material";

const CustomMenu = ({ children, anchorEl, onClose }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={Boolean(anchorEl)}
      onClose={onClose}
      onClick={onClose}
      PaperProps={{
        elevation: 0,
        sx: {
          minWidth: "160px",
          overflow: "visible",
          filter: "drop-shadow(0px 2px 4px rgba(0,0,0,0.32))",
          mt: 1.5,
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 15,
            width: 10,
            height: 10,
            backgroundColor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      {children}
    </Menu>
  );
};

export default CustomMenu;
