import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import Dialog from "../../components/Dialog";
import apis from "../../apis";
import {
  validateRequired as checkRequired,
  validatePhoneNumber as checkPhoneNumber,
  validateEmail as checkEmail,
  validateNumeric as checkNumeric,
} from "../../utils/validate";
import { COLOR } from "../../styles/color";
import { DIALOG_TYPE, ROLE_TYPE } from "../../constants";

const INIT_USER_ERROR = {
  name: "",
  phoneNumber: "",
  password: "",
  roleId: "",
};

const UserDialog = ({
  open,
  handleClose,
  initUser,
  handleReload,
  roles,
  dialogType,
  provinces,
}) => {
  const { t } = useTranslation();

  const [user, setUser] = useState({});
  const [userError, setUserError] = useState(INIT_USER_ERROR);
  const [loading, setLoading] = useState(false);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);

  const handleChangeUser = (event, field) => {
    const value = event.target.value;
    setUser((prev) => ({ ...prev, [field]: value }));
    setUserError((prev) => ({ ...prev, [field]: "" }));
  };

  const handleChangeProvince = (newValue) => {
    setUser((prev) => ({
      ...prev,
      province: newValue,
      district: "",
      ward: "",
    }));
    setWards([]);
    if (!newValue) setDistricts([]);
  };

  const handleChangeDistrict = (newValue) => {
    setUser((prev) => ({ ...prev, district: newValue, ward: "" }));
    if (!newValue) setWards([]);
  };

  const handleChangeWard = (newValue) => {
    setUser((prev) => ({ ...prev, ward: newValue }));
  };

  const handleCloseDialog = () => {
    handleClose();
    setDistricts([]);
    setWards([]);
    setUser({});
    setUserError(INIT_USER_ERROR);
  };

  const validateRequired = (field) => {
    if (!checkRequired(user[field])) {
      setUserError((prev) => ({
        ...prev,
        [field]: t("fieldRequired"),
      }));
      return false;
    }
    return true;
  };

  const validatePhoneNumber = (field) => {
    if (user[field] && !checkPhoneNumber(user[field])) {
      setUserError((prev) => ({
        ...prev,
        [field]: t("invalidPhoneNumber"),
      }));
      return false;
    }
    return true;
  };

  const validateEmail = (field) => {
    if (user[field] && !checkEmail(user[field])) {
      setUserError((prev) => ({
        ...prev,
        [field]: t("emailInvalid"),
      }));
      return false;
    }
    return true;
  };

  const validateNumeric = (field) => {
    if (user[field] && !checkNumeric(user[field], 12)) {
      setUserError((prev) => ({
        ...prev,
        [field]: t("dataInvalid"),
      }));
      return false;
    }

    return true;
  };

  const validate = () =>
    validateRequired("name") &&
    validateRequired("phoneNumber") &&
    validatePhoneNumber("phoneNumber") &&
    validateRequired("roleId");

  const getUserData = () => {
    const {
      roleType,
      name,
      phoneNumber,
      password,
      roleId,
      email,
      citizenIdentification,
      province,
      district,
      ward,
      addressDetail,
    } = user;

    return {
      ...(roleType === ROLE_TYPE.MINI_APP ? {} : { name, password, roleId }),
      phoneNumber,
      metadata: {
        name,
        email,
        citizenIdentification,
        address: { province, district, ward, addressDetail },
      },
    };
  };

  const handleCreateUser = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      await apis.user.createUser(getUserData());
      handleCloseDialog();
      toast.success(t("addUserSuccess"));
      handleReload();
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const handleUpdateUser = async () => {
    if (!validate()) return;
    setLoading(true);
    try {
      const userData = getUserData();
      userData.roleType = user.roleType;
      delete userData.phoneNumber;
      await apis.user.updateUser(user.id, userData);
      handleCloseDialog();
      toast.success(t("editUserSuccess"));
      handleReload();
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const getDistricts = () => {
    const province = provinces.find((p) => p.name === user.province);
    setDistricts(province?.districts || []);
    if (user.district) getWards(province?.districts || []);
  };

  const getWards = () => {
    const district = districts.find((d) => d.name === user.district);
    setWards(district?.wards || []);
  };

  useEffect(() => {
    if (user.province) getDistricts();
  }, [user.province]);

  useEffect(() => {
    if (user.district) getWards();
  }, [user.district]);

  useEffect(() => {
    if (open) setUser(initUser);
  }, [open, initUser]);

  return (
    <Dialog
      title={
        dialogType === DIALOG_TYPE.CREATE ? t("addNewUser") : t("editUser")
      }
      maxWidth="sm"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        <Box>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("fullName")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                error={!!userError.name}
                helperText={userError.name}
                value={user.name}
                size="small"
                fullWidth
                onChange={(event) => handleChangeUser(event, "name")}
                onBlur={() => validateRequired("name")}
                placeholder={t("fullName")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("phoneNumber")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                error={!!userError.phoneNumber}
                helperText={userError.phoneNumber}
                value={user.phoneNumber}
                size="small"
                fullWidth
                onChange={(event) => handleChangeUser(event, "phoneNumber")}
                onBlur={() =>
                  validateRequired("phoneNumber") &&
                  validatePhoneNumber("phoneNumber")
                }
                placeholder={t("phoneNumber")}
                disabled={dialogType === DIALOG_TYPE.UPDATE}
              />
            </Grid>
            {!(user?.roleType === ROLE_TYPE.MINI_APP) && (
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  {t("role")} <span style={{ color: COLOR.error.base }}>*</span>
                  :
                </Typography>
                <FormControl fullWidth error={!!userError.roleId}>
                  <Select
                    value={user.roleId}
                    onChange={(event) => handleChangeUser(event, "roleId")}
                    onBlur={() => validateRequired("roleId")}
                    size="small"
                  >
                    {roles
                      .filter(
                        (role) =>
                          !role.isMasterRole && role.type === ROLE_TYPE.ADMIN
                      )
                      .map((role) => (
                        <MenuItem key={role.id} value={role.id}>
                          {role.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>{userError.roleId}</FormHelperText>
                </FormControl>
              </Grid>
            )}
            {!(user?.roleType === ROLE_TYPE.MINI_APP) && (
              <Grid item xs={12} sm={6}>
                <Typography fontWeight={500} mb={1}>
                  {t("password")}:
                </Typography>
                <TextField
                  error={!!userError.password}
                  helperText={userError.password}
                  value={user.password}
                  size="small"
                  fullWidth
                  onChange={(event) => handleChangeUser(event, "password")}
                  placeholder={t("password")}
                  type="password"
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("email")}:
              </Typography>
              <TextField
                value={user.email}
                size="small"
                fullWidth
                onChange={(event) => handleChangeUser(event, "email")}
                onBlur={() => validateEmail("email")}
                error={!!userError.email}
                helperText={userError.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography fontWeight={500} mb={1}>
                {t("citizenIdentification")}:
              </Typography>
              <TextField
                value={user.citizenIdentification}
                size="small"
                fullWidth
                onChange={(event) =>
                  handleChangeUser(event, "citizenIdentification")
                }
                onBlur={() => validateNumeric("citizenIdentification")}
                error={!!userError.citizenIdentification}
                helperText={userError.citizenIdentification}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("province")}:
              </Typography>
              <Autocomplete
                size="small"
                value={user.province || null}
                options={provinces.map((province) => province.name)}
                filterSelectedOptions
                onChange={(event, newValue) => handleChangeProvince(newValue)}
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {key}
                  </MenuItem>
                )}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("district")}:
              </Typography>
              <Autocomplete
                size="small"
                value={user.district || null}
                options={districts.map((district) => district.name)}
                filterSelectedOptions
                onChange={(event, newValue) => handleChangeDistrict(newValue)}
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {key}
                  </MenuItem>
                )}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Typography fontWeight={500} mb={1}>
                {t("ward")}:
              </Typography>
              <Autocomplete
                size="small"
                value={user.ward || null}
                options={wards.map((ward) => ward.name)}
                filterSelectedOptions
                onChange={(event, newValue) => handleChangeWard(newValue)}
                renderOption={(props, key) => (
                  <MenuItem value={key} {...props}>
                    {key}
                  </MenuItem>
                )}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontWeight={500} mb={1}>
                {t("addressDetail")}:
              </Typography>
              <TextField
                value={user.addressDetail || ""}
                size="small"
                fullWidth
                onChange={(event) => handleChangeUser(event, "addressDetail")}
                multiline={true}
                rows={2}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        {dialogType === DIALOG_TYPE.CREATE ? (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleCreateUser}
          >
            {t("addNew")}
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdateUser}
          >
            {t("save")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UserDialog;
