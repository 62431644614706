import { SERVICE } from "../constants";
import api from "./api";

export const getRegions = async (params) => {
  const res = await api({
    method: "GET",
    url: `/regions`,
    params,
    source: SERVICE.VELA_API,
    stopPaths: ["result.districts"],
  });
  return res;
};
