const ROUTE = {
  DASHBOARD: "/",
  LOGIN: "/login",
  PERMISSION: "/permission",
  ROLE: "/role",
  USER: "/user",
  DISTRIBUTOR: "/distributor",
  STORE: "/store",
  TIMESHEET: "/timesheet",
  TIMESHEET_DETAIL: "/timesheet/user/:userId",
  DISCOUNT: "/discount",
  ORDER: "/order",
};

export default ROUTE;
