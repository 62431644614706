import axios from "axios";
import camelCase from "camelcase-keys";
import { API_URL } from "../configs";
import getErrorMessage from "../errors/getErrorMessage";

const axiosClient = axios.create({
  baseURL: `${API_URL}/api/v1`,
  responseType: "json",
  timeout: 20 * 1000,
});

axiosClient.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);

axiosClient.interceptors.response.use(
  (response) => {
    if (!response) return {};

    const {
      data,
      config: { source, stopPaths = [] },
    } = response;
    let newData = {};
    if (data) {
      newData = camelCase(data, { deep: true, stopPaths });
    }

    if (!newData.status) {
      const errorMessage = getErrorMessage(
        newData.code,
        source,
        newData.message
      );
      throw new Error(errorMessage);
    }

    return newData;
  },
  (error) => {
    // TODO: Handle timeout error
    if (error.response && error.response.status) {
      const { config = {}, response } = error;
      const { source } = config;
      const errorMessage = getErrorMessage(
        response.status,
        source,
        response.message
      );
      throw new Error(errorMessage);
    }
  }
);

export default axiosClient;
