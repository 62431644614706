import { SERVICE } from "../constants";
import api from "./api";

const getDistricts = async (provinceId) => {
  const response = await api({
    method: "GET",
    url: `/districts`,
    params: { provinceId },
    source: SERVICE.VELA_API,
  });

  return response;
};

const getWards = async (districtId) => {
  const response = await api({
    method: "GET",
    url: `/wards`,
    params: { districtId },
    source: SERVICE.VELA_API,
  });

  return response;
};

const getVnUnits = async (region) => {
  const response = await api({
    method: "GET",
    url: `/vn-administrative-units`,
    params: { region },
    source: SERVICE.VELA_API,
  });

  return response;
};

export { getDistricts, getWards, getVnUnits };
