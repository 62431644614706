import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Table from "../../components/Table";
import ROUTE from "../../constants/route";
import { formatNumber } from "../../utils/number";

const UserList = ({ loading, paging, handleChangePage, users }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickRow = (row) =>
    history.push(`${ROUTE.TIMESHEET}/user/${row.id}`);

  const columns = [
    {
      title: t("fullName"),
      align: "left",
      render: (row) => row.metadata?.name || row.name,
    },
    {
      title: t("phoneNumber"),
      align: "left",
      field: "phoneNumber",
    },
    {
      title: t("role"),
      align: "left",
      render: (row) => row.role.name,
    },
    {
      title: `${t("totalTraveledDistance")} (${t("meter")})`,
      align: "center",
      render: (row) => formatNumber(row.totalTraveledDistance),
    },
  ];

  return (
    <>
      <Table
        data={users}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
        onRowClick={handleClickRow}
      />
    </>
  );
};

export default UserList;
