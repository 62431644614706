import { Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";

import { INITIAL_PAGING, PAGE_TYPE } from "../../constants";
import apis from "../../apis";
import StoreList from "./StoreList";
import useSearchParams from "../../hooks/useSearchParams";

const Store = () => {
  const location = useLocation();
  const { t } = useTranslation();

  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [stores, setStores] = useState([]);
  const [loading, setLoading] = useState(true);
  const [distributors, setDistributors] = useState([]);

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const fetchStores = async (newFilter) => {
    setLoading(true);
    try {
      const condition = {
        limit: paging.limit,
        page: newFilter.page,
      };

      const { result } = await apis.store.getStores(condition);
      setStores(result.stores);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const fetchDistributors = async () => {
    try {
      const condition = {
        pageType: PAGE_TYPE.DISTRIBUTOR,
      };

      const { result } = await apis.user.getUsers(condition);
      setDistributors(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const { page = INITIAL_PAGING.page } = searchParams;
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchStores({ page: parseInt(page, 10) });
  }, [location.search]);

  useEffect(() => {
    fetchDistributors();
  }, []);

  return (
    <>
      <PageTitle title={t("store")} />
      <MainCard>
        <Grid container spacing={2} marginBottom={2}>
          <Grid item xs={12} sm={6} md={6} lg={4}>
            {/* <TextField
              value={filter.search}
              size="small"
              fullWidth
              placeholder={t("userSearch")}
              onChange={handleChangeSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="end">
                    <Search />
                  </InputAdornment>
                ),
              }}
            /> */}
          </Grid>
          <Grid item xs={12} sm={3} md={3} lg={2}></Grid>
          <Grid item xs={12} sm={3} md={3} lg={6}>
            <Stack
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              {/* <Button
                variant="contained"
                startIcon={<Add />}
                // onClick={handleOpenQrDialog}
              >
                {t("addDistributor")}
              </Button> */}
            </Stack>
          </Grid>
        </Grid>
        <StoreList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          stores={stores}
          distributors={distributors}
        />
      </MainCard>
    </>
  );
};

export default Store;
