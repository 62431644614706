import dayjs from "dayjs";

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validatePhoneNumber = (phoneNumber) => {
  return /(((\+|)84)|0)(3|5|7|8|9)+([0-9]{8})\b/.test(phoneNumber);
};

export const validateRequired = (value) => {
  return !validateEnum(value, ["", null, undefined]);
};

export const validateEnum = (value, validValues = []) => {
  return validValues.includes(value);
};

export const validateInt = (
  value,
  { min, max, minLength, maxLength, length }
) => {
  const intValue = parseInt(value || 0, 10);
  let isValid = true;
  if (typeof min !== "undefined" && intValue < min) isValid = false;
  if (typeof max !== "undefined" && intValue > max) isValid = false;
  if (typeof minLength !== "undefined" && value.length < minLength)
    isValid = false;
  if (typeof maxLength !== "undefined" && value.length > maxLength)
    isValid = false;
  if (typeof length !== "undefined" && value.length !== length) isValid = false;

  return isValid;
};

export const validateDate = (value, format) =>
  dayjs(value, format, true).isValid();

export const validateNumeric = (value, length) => {
  let isValid = true;
  if (!/^([0-9]\d*)$/.test(value)) isValid = false;
  if (typeof length !== "undefined" && value.length !== length) isValid = false;

  return isValid;
};
