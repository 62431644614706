import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import camelcase from "camelcase";
import { Box, CircularProgress, Grid, Tab, Tabs } from "@mui/material";

import apis from "../../apis";
import { ROLE_TYPE } from "../../constants";
import GroupPermissionList from "./GroupPermissionList";
import { StyledPermission } from "./index.style";
import PermissionList from "./PermissionList";

const Permission = () => {
  const { t } = useTranslation();

  const [groupPermissions, setGroupPermissions] = useState([]);
  const [selectedGroupPermission, setSelectedGroupPermission] = useState({});
  const [tab, setTab] = useState(ROLE_TYPE.MINI_APP);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchPermissions = async () => {
    try {
      const res = await apis.permission.getPermissions();
      const { result = [] } = res;
      setPermissions(result);
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const fetchGroupPermissions = async (firstFetch = false) => {
    try {
      const res = await apis.groupPermission.getGroupPermissions();
      const { result = [] } = res;
      setGroupPermissions(result);
      if (firstFetch) {
        const firstGroupPermission = result.find((gp) => gp.type === tab);
        setSelectedGroupPermission(
          firstGroupPermission ? firstGroupPermission : {}
        );
      }
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const init = async () => {
    setLoading(true);
    await Promise.all([fetchGroupPermissions(true), fetchPermissions()]);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    const firstGroupPermission = groupPermissions.find(
      (gp) => gp.type === newValue
    );

    setSelectedGroupPermission(
      firstGroupPermission ? firstGroupPermission : {}
    );
  };

  if (loading) {
    return (
      <Box p={2}>
        <CircularProgress color="primary" />
      </Box>
    );
  }

  return (
    <StyledPermission>
      <Box className="content-container">
        <Grid container>
          <Grid item xs={3}>
            <Box className="list-container">
              <Box className="list">
                <Tabs
                  value={tab}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChangeTab}
                  aria-label="disabled tabs example"
                  className="tabs"
                >
                  {Object.values(ROLE_TYPE).map((value) => (
                    <Tab
                      value={value}
                      label={t(camelcase(value))}
                      key={value}
                      className="tab"
                    />
                  ))}
                </Tabs>
                <GroupPermissionList
                  groupPermissionType={tab}
                  groupPermissions={groupPermissions.filter(
                    (el) => el.type === tab
                  )}
                  permissions={permissions}
                  selectedGroupPermission={selectedGroupPermission}
                  setSelectedGroupPermission={setSelectedGroupPermission}
                  onReload={fetchGroupPermissions}
                />
              </Box>
            </Box>
          </Grid>
          {!!Object.keys(selectedGroupPermission).length && (
            <Grid item xs={9}>
              <PermissionList
                permissions={permissions}
                selectedGroupPermission={selectedGroupPermission}
                onReload={fetchPermissions}
              />
            </Grid>
          )}
        </Grid>
      </Box>
    </StyledPermission>
  );
};

export default Permission;
