import { useState } from "react";
import { IconButton, MenuItem } from "@mui/material";
import { Translate } from "@mui/icons-material";

import CustomMenu from "../CustomMenu";
import { useTranslation } from "react-i18next";
import { LANGUAGE } from "../../constants";

const Language = () => {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <IconButton onClick={handleMenu} color="inherit">
        <Translate color="black" />
      </IconButton>
      <CustomMenu anchorEl={anchorEl} onClose={handleClose}>
        {Object.keys(LANGUAGE).map((key) => (
          <MenuItem
            key={LANGUAGE[key]}
            onClick={() => handleChangeLanguage(LANGUAGE[key])}
            selected={i18n.language === LANGUAGE[key]}
          >
            {t(LANGUAGE[key])}
          </MenuItem>
        ))}
      </CustomMenu>
    </>
  );
};

export default Language;
