import { SERVICE } from "../constants";
import api from "./api";

export const getPermissions = async (params) => {
  const res = await api({
    method: "GET",
    url: `/permissions`,
    params,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

export const createPermission = async (data) => {
  const res = await api({
    method: "POST",
    url: `/permissions`,
    data,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

export const updatePermission = async (permissionId, data) => {
  const res = await api({
    method: "PUT",
    url: `/permissions/${permissionId}`,
    data,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

export const deletePermission = async (permissionId) => {
  const res = await api({
    method: "DELETE",
    url: `/permissions/${permissionId}`,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};
