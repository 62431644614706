import { useState } from "react";
import Box from "@mui/material/Box";

import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import { TOP_BAR_HEIGHT } from "../../styles/config";

const Layout = ({ children }) => {
  const [openMobileSidebar, setOpenMobileSidebar] = useState(false);
  const [openMiniSidebar, setOpenMiniSidebar] = useState(false);

  const onMobileSidebarToggle = () => {
    setOpenMobileSidebar((prev) => !prev);
  };

  return (
    <Box display="flex">
      <Sidebar
        openMiniSidebar={openMiniSidebar}
        openMobileSidebar={openMobileSidebar}
        onMobileSidebarToggle={onMobileSidebarToggle}
      />
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <Header
          onMobileSidebarToggle={onMobileSidebarToggle}
          openMiniSidebar={openMiniSidebar}
          setOpenMiniSidebar={setOpenMiniSidebar}
        />
        <Box
          component="main"
          sx={{
            height: `calc(100vh - ${TOP_BAR_HEIGHT + 1}px)`,
            overflowY: "auto",
          }}
        >
          <Box padding={2}>{children}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Layout;
