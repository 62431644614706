import { SERVICE } from "../constants";
import api from "./api";

const getUsers = async (params) => {
  const res = await api({
    method: "GET",
    url: `/users`,
    params,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

const createUser = async (data) => {
  const response = await api({
    method: "POST",
    url: "/users",
    data,
    source: SERVICE.VELA_AUTH,
  });

  return response;
};

const updateUser = async (userId, data) => {
  const response = await api({
    method: "PUT",
    url: `/users/${userId}`,
    data,
    source: SERVICE.VELA_AUTH,
  });

  return response;
};

const transferDistributor = async (userId, distributorId) => {
  const response = await api({
    method: "PUT",
    url: `/users/${userId}/transfer-distributor`,
    data: { distributorId },
    source: SERVICE.VELA_AUTH,
  });

  return response;
};

const changeStatus = async (userId, active) => {
  const response = await api({
    method: "PUT",
    url: `/users/${userId}/change-status`,
    data: { active },
    source: SERVICE.VELA_AUTH,
  });

  return response;
};

const getUser = async (userId) => {
  const response = await api({
    method: "GET",
    url: `/users/${userId}`,
    source: SERVICE.VELA_AUTH,
  });

  return response;
};

export {
  getUsers,
  createUser,
  updateUser,
  transferDistributor,
  changeStatus,
  getUser,
};
