import { SERVICE } from "../constants";
import api from "./api";

export const createGroupPermission = async (data) => {
  const res = await api({
    method: "POST",
    url: "/group-permissions",
    data,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

export const getGroupPermissions = async (params) => {
  const res = await api({
    method: "GET",
    url: "/group-permissions",
    params,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

export const updateGroupPermission = async (groupPermissionId, data) => {
  const res = await api({
    method: "PUT",
    url: `/group-permissions/${groupPermissionId}`,
    data,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

export const deleteGroupPermission = async (groupPermissionId) => {
  const res = await api({
    method: "DELETE",
    url: `/group-permissions/${groupPermissionId}`,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};
