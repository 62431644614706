import { SERVICE } from "../constants";
import { SYSTEM_ERROR_CODE } from "./code";
import { VELA_AUTH_ERROR_MESSAGE } from "./message";

const getErrorMessage = (code, service, serverErrorMessage) => {
  let message;

  switch (service) {
    case SERVICE.VELA_AUTH:
      message = VELA_AUTH_ERROR_MESSAGE[code];
      break;
    default:
  }

  if (message) return message;

  switch (code) {
    case SYSTEM_ERROR_CODE.BAD_REQUEST:
      return "badRequest";
    case SYSTEM_ERROR_CODE.UNAUTHORIZED:
      return "unauthorized";
    case SYSTEM_ERROR_CODE.FORBIDDEN:
      return "forbidden";
    case SYSTEM_ERROR_CODE.NOT_FOUND:
      return "notFound";

    default: {
      if (serverErrorMessage) return serverErrorMessage;
      return "serverError";
    }
  }
};

export default getErrorMessage;
