import { COLOR } from "../color";

const CustomPalette = () => {
  return {
    white: { main: COLOR.white[100] },
    black: { main: COLOR.black[64] },
    edit: {
      main: "#8b78cd",
    },
  };
};

export default CustomPalette;
