import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Add, Delete, Edit } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import Table from "../../components/Table";
import PermissionType from "./PermissionType";
import Dialog from "../../components/Dialog";
import { HTTP_METHOD, PERMISSION_TYPE } from "../../constants";
import { COLOR } from "../../styles/color";
import { validateRequired as checkRequired } from "../../utils/validate";
import Popup from "../../components/Popup";

const INIT_PERMISSION_ERROR = {
  name: "",
  path: "",
  type: "",
  method: "",
};

const INIT_PERMISSION = {
  name: "",
  path: "",
  type: "",
  method: "",
};

const PermissionList = ({
  permissions = [],
  selectedGroupPermission,
  onReload,
}) => {
  const { t } = useTranslation();
  const [permission, setPermission] = useState(INIT_PERMISSION);
  const [errorPermission, setErrorPermission] = useState(INIT_PERMISSION_ERROR);
  const [dialogType, setDialogType] = useState("create");
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const handleOpenDialog = (type, object) => {
    setDialogType(type);
    if (object) setPermission(object);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setErrorPermission(INIT_PERMISSION_ERROR);
    setPermission(INIT_PERMISSION);
  };

  const handleOpenPopup = (permission) => {
    setPermission(permission);
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setPermission(INIT_PERMISSION);
  };

  const handChangePermission = (event, field) => {
    const value = event.target.value;
    setPermission((prev) => ({ ...prev, [field]: value }));
    setErrorPermission((prev) => ({ ...prev, [field]: false }));
    if (field === "type" && value === PERMISSION_TYPE.MENU)
      setPermission((prev) => ({ ...prev, method: HTTP_METHOD.GET }));
  };

  const validateRequired = (field) => {
    if (!checkRequired(permission[field])) {
      setErrorPermission((prev) => ({
        ...prev,
        [field]: t("fieldRequired"),
      }));
      return false;
    }
    return true;
  };

  const validate = () =>
    Object.keys(INIT_PERMISSION).some((key) => validateRequired(key));

  const handleCreatePermission = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      await apis.permission.createPermission({
        ...permission,
        groupPermissionId: selectedGroupPermission.id,
      });
      handleCloseDialog();
      onReload();
      toast.success(t("addPermissionSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const handleUpdatePermission = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      const { name, type, method, path } = permission;
      await apis.permission.updatePermission(permission.id, {
        name,
        type,
        method,
        path,
      });
      handleCloseDialog();
      onReload();
      toast.success(t("editPermissionSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const handleDeletePermission = async () => {
    try {
      await apis.permission.deletePermission(permission.id);
      onReload();
      toast.success(t("deletePermissionSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const columns = [
    {
      field: "name",
      title: t("permissionName"),
      align: "left",
    },
    {
      field: "method",
      title: t("method"),
      align: "left",
    },
    {
      field: "path",
      title: t("path"),
      align: "left",
    },
    {
      title: t("permissionType"),
      align: "left",
      render: (row) => <PermissionType type={row.type} />,
    },
    {
      title: "",
      align: "center",
      render: (row) => (
        <Stack direction="row" justifyContent="center">
          <Tooltip title={t("edit")}>
            <IconButton
              color="edit"
              onClick={() => handleOpenDialog("update", row)}
            >
              <Edit />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("delete")}>
            <IconButton color="error" onClick={() => handleOpenPopup(row)}>
              <Delete />
            </IconButton>
          </Tooltip>
        </Stack>
      ),
    },
  ];

  return (
    <>
      <Box
        display="flex"
        paddingX={1}
        height={48}
        alignItems="center"
        gap={1.5}
      >
        <Typography marginRight="auto">{t("permissionList")}</Typography>
        <Button
          variant="contained"
          startIcon={<Add />}
          onClick={() => handleOpenDialog("create")}
        >
          {t("addPermission")}
        </Button>
      </Box>
      <Table
        columns={columns}
        data={permissions.filter(
          (p) => p.groupPermissionId === selectedGroupPermission.id
        )}
        page={1}
        limit={1000}
        showNumber
        disablePagination
      />
      <Dialog
        title={
          dialogType === "create" ? t("addPermission") : t("editPermission")
        }
        maxWidth="xs"
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogContent>
          {permission && (
            <>
              <Box mb={3}>
                <Typography fontWeight={500} mb={1}>
                  {t("permissionName")}{" "}
                  <span style={{ color: COLOR.error.base }}>*</span>:
                </Typography>
                <TextField
                  error={!!errorPermission.name}
                  helperText={errorPermission.name}
                  value={permission.name}
                  size="small"
                  fullWidth
                  onChange={(event) => handChangePermission(event, "name")}
                  placeholder={t("permissionName")}
                />
              </Box>
              <Box mb={3}>
                <Typography fontWeight={500} mb={1}>
                  {t("permissionType")}{" "}
                  <span style={{ color: COLOR.error.base }}>*</span>:
                </Typography>
                <FormControl fullWidth error={!!errorPermission.type}>
                  <Select
                    value={permission.type}
                    size="small"
                    onChange={(event) => handChangePermission(event, "type")}
                  >
                    {Object.keys(PERMISSION_TYPE).map((key) => (
                      <MenuItem
                        key={PERMISSION_TYPE[key]}
                        value={PERMISSION_TYPE[key]}
                      >
                        {PERMISSION_TYPE[key]}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errorPermission.type}</FormHelperText>
                </FormControl>
              </Box>
              <Box mb={3}>
                <Typography fontWeight={500} mb={1}>
                  {t("method")}{" "}
                  <span style={{ color: COLOR.error.base }}>*</span>:
                </Typography>
                <FormControl fullWidth error={!!errorPermission.method}>
                  <Select
                    value={permission.method}
                    size="small"
                    onChange={(event) => handChangePermission(event, "method")}
                  >
                    {permission.type === PERMISSION_TYPE.MENU ? (
                      <MenuItem value={HTTP_METHOD.GET}>
                        {HTTP_METHOD.GET}
                      </MenuItem>
                    ) : (
                      Object.keys(HTTP_METHOD).map((key) => (
                        <MenuItem
                          key={HTTP_METHOD[key]}
                          value={HTTP_METHOD[key]}
                        >
                          {HTTP_METHOD[key]}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                  <FormHelperText>{errorPermission.method}</FormHelperText>
                </FormControl>
              </Box>
              <Box mb={3}>
                <Typography fontWeight={500} mb={1}>
                  {t("path")} <span style={{ color: COLOR.error.base }}>*</span>
                  :
                </Typography>
                <TextField
                  error={!!errorPermission.path}
                  helperText={errorPermission.path}
                  value={permission.path}
                  size="small"
                  fullWidth
                  onChange={(event) => handChangePermission(event, "path")}
                  placeholder={t("path")}
                />
              </Box>
            </>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCloseDialog}
          >
            {t("cancel")}
          </Button>
          {dialogType === "create" ? (
            <LoadingButton
              variant="contained"
              color="success"
              loading={loading}
              onClick={handleCreatePermission}
            >
              {t("addNew")}
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="contained"
              color="success"
              loading={loading}
              onClick={handleUpdatePermission}
            >
              {t("save")}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
      <Popup
        open={openPopup}
        onClose={handleClosePopup}
        onOk={handleDeletePermission}
        okMessage={t("accept")}
        title={t("areYouSureDeletePermission", {
          permission: permission.name,
        })}
      />
    </>
  );
};

export default PermissionList;
