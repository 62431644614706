import { SERVICE } from "../constants";
import api from "./api";

const createAddAsmQr = async ({ region }) => {
  const res = await api({
    method: "POST",
    url: `/qr/add-asm`,
    data: { region },
    source: SERVICE.VELA_API,
  });
  return res;
};

const createAddSupervisorQr = async ({ region, distributorIds }) => {
  const res = await api({
    method: "POST",
    url: `/qr/add-supervisor`,
    data: { region, distributorIds },
    source: SERVICE.VELA_API,
  });
  return res;
};

const createAddDistributorQr = async ({ region, supervisorId, metadata }) => {
  const res = await api({
    method: "POST",
    url: `/qr/add-distributor`,
    data: { region, supervisorId, metadata },
    source: SERVICE.VELA_API,
  });
  return res;
};

export { createAddAsmQr, createAddSupervisorQr, createAddDistributorQr };
