import { DeleteOutlined, Edit } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import Table from "../../components/Table";
import { DIALOG_TYPE } from "../../constants";
import { COLOR } from "../../styles/color";
import { APPLIED_OBJECT_MAPPING } from "../../constants/discount";

const getDiscountConditionText = (discount = {}) => {
  const { condition } = discount;
  if (!condition) return "";
  const { type, amount, product } = condition;
  if (type === "PRODUCT") {
    return `${amount} ${product.name}`;
  }
  if (type === "MONEY") {
    return `${amount?.toLocaleString()} VNĐ`;
  }
  return "";
};

const getDiscountValueText = (discount = {}) => {
  const { value } = discount;
  if (!value) return "";
  const { type, amount, product } = value;
  if (type === "PRODUCT") {
    return `${amount} ${product.name}`;
  }
  if (type === "MONEY") {
    return `${amount?.toLocaleString()} VNĐ`;
  }
  return "";
};

const getDiscountAppliedObject = (discount = {}) => {
  const { appliedObject } = discount;
  if (!appliedObject) return "";
  const { type, value } = appliedObject;
  if (type === "ALL") return "Tất cả cửa hàng";
  return `${APPLIED_OBJECT_MAPPING[type] || ""} ${value
    ?.map((item) => item.name || "")
    .join(", ")}`;
};

const DiscountList = ({
  loading,
  paging,
  handleChangePage,
  discounts,
  handleOpenDiscountDialog,
  handleOpenDeleteDiscountPopup,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("name"),
      align: "left",
      field: "name",
    },
    {
      title: t("condition"),
      align: "left",
      render: (row) => <Typography>{getDiscountConditionText(row)}</Typography>,
    },
    {
      title: t("discountValue"),
      align: "left",
      render: (row) => <Typography>{getDiscountValueText(row)}</Typography>,
    },
    {
      title: t("appliedObject"),
      align: "left",
      render: (row) => <Typography>{getDiscountAppliedObject(row)}</Typography>,
    },
    {
      title: t("status"),
      align: "center",
      render: (row) => (
        <>
          {row.status === "ACTIVE" ? (
            <Typography sx={{ color: COLOR.success.base }}>
              {t("active")}
            </Typography>
          ) : (
            <Typography sx={{ color: COLOR.error.base }}>
              {t("inactive")}
            </Typography>
          )}
        </>
      ),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("edit")}>
              <span>
                <IconButton
                  onClick={() =>
                    handleOpenDiscountDialog(DIALOG_TYPE.UPDATE, row)
                  }
                  color="edit"
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={t("delete")}>
              <span>
                <IconButton
                  onClick={() => handleOpenDeleteDiscountPopup(row)}
                  color={"error"}
                >
                  <DeleteOutlined />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={discounts}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default DiscountList;
