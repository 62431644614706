import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { COLOR } from "../../styles/color";
import { BORDER_RADIUS } from "../../styles/config";

export const StyledPermission = styled(Box)`
  background-color: ${COLOR.white[100]};
  padding: 15px;
  border-radius: ${BORDER_RADIUS.container};
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  .content-container {
    background-color: ${COLOR.white[100]};
    border: 1px solid ${COLOR.pastelBlue[100]};
    border-radius: 6px;
  }

  .list-container {
    min-height: 60vh;
    border-right: 1px solid ${COLOR.pastelBlue[100]};
  }

  .tabs {
    border-bottom: 1px solid ${COLOR.pastelBlue[100]};
    width: auto;
  }
  .tab {
    text-transform: none;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    padding: 0px 20px;
    width: 50%;
  }

  .tab-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 6px 6px 6px 17px;

    .tab-label {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }

    .btn-delete-group-permission {
      color: ${COLOR.dark};
      border-color: ${COLOR.dark};
    }
  }
`;

export const StyledPermissionType = styled(Typography)`
  background: ${(props) => props.background};
  padding: 3px 10px;
  border-radius: 4px;
  color: ${(props) => props.color};
  width: 50px;
  text-align: center;
`;

export const StyledGroupPermissionList = styled(Box)`
  .item {
    padding: 8px 18px;
    margin: 6px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: 30px;
    border-left: 3px solid ${COLOR.white[100]};

    &:hover {
      background-color: #f4f4f4;
    }

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: ${COLOR.dark};
    }

    .description {
      font-size: 14px;
      line-height: 21px;
      color: ${COLOR.bodyText};
    }
  }

  .item-selected {
    border-left-color: ${COLOR.primary.base};
    background-color: ${COLOR.standardBlue[8]};

    &:hover {
      background-color: ${COLOR.standardBlue[8]};
    }
  }
`;
