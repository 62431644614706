import React, { useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import apis from "../../apis";
import Dialog from "../../components/Dialog";
import { validateRequired as checkRequired } from "../../utils/validate";
import { COLOR } from "../../styles/color";

const INIT_ROLE_ERROR = {
  name: "",
  description: "",
};

const RoleDialog = ({
  open,
  handleClose,
  dialogType,
  roleType,
  role,
  setRole,
  reload,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [errorRole, setErrorRole] = useState(INIT_ROLE_ERROR);

  const handleCloseDialog = () => {
    setErrorRole(INIT_ROLE_ERROR);
    handleClose();
  };

  const handleChangeRole = (event, field) => {
    const value = event.target.value;
    setRole((prev) => ({ ...prev, [field]: value }));
    setErrorRole((prev) => ({ ...prev, [field]: false }));
  };

  const validateRequired = (field) => {
    if (!checkRequired(role[field])) {
      setErrorRole((prev) => ({
        ...prev,
        [field]: t("fieldRequired"),
      }));
      return false;
    }
    return true;
  };

  const validate = () => validateRequired("name");

  const handleCreateRole = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      await apis.role.createRole({
        ...role,
        type: roleType,
      });
      handleCloseDialog();
      reload();
      toast.success(t("addRoleSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const handleUpdateRole = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      await apis.role.updateRole(role.id, {
        name: role.name,
        description: role.description,
      });
      handleCloseDialog();
      reload();
      toast.success(t("editRoleSuccess"));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  return (
    <Dialog
      title={dialogType === "create" ? t("addRole") : t("editRole")}
      maxWidth="xs"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogContent>
        {!!Object.keys(role).length && (
          <>
            <Box mb={3}>
              <Typography fontWeight={500} mb={1}>
                {t("roleName")}{" "}
                <span style={{ color: COLOR.error.base }}>*</span>:
              </Typography>
              <TextField
                helperText={errorRole.name}
                error={!!errorRole.name}
                value={role.name}
                size="small"
                fullWidth
                onChange={(event) => handleChangeRole(event, "name")}
                onBlur={() => validateRequired("name")}
                placeholder={t("roleName")}
              />
            </Box>
            <Box mb={3}>
              <Typography fontWeight={500} mb={1}>
                {t("description")}:
              </Typography>
              <TextField
                helperText={errorRole.description}
                error={!!errorRole.description}
                value={role.description}
                size="small"
                fullWidth
                onChange={(event) => handleChangeRole(event, "description")}
                placeholder={t("description")}
                multiline={true}
                rows={2}
              />
            </Box>
          </>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        {dialogType === "create" ? (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleCreateRole}
          >
            {t("addNew")}
          </LoadingButton>
        ) : (
          <LoadingButton
            variant="contained"
            color="success"
            loading={loading}
            onClick={handleUpdateRole}
          >
            {t("save")}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RoleDialog;
