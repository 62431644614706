import { SERVICE } from "../constants";
import api from "./api";

const login = async ({ phoneNumber, password }) => {
  const loginInfo = await api({
    method: "POST",
    url: `/auth/login`,
    data: { phoneNumber, password },
    source: SERVICE.VELA_AUTH,
  });
  return loginInfo;
};

const getMe = async () => {
  const me = await api({
    method: "GET",
    url: `/users/me`,
    source: SERVICE.VELA_AUTH,
  });
  return me;
};

const logout = async () => {
  const logoutInfo = await api({
    method: "GET",
    url: `/auth/logout`,
    source: SERVICE.VELA_AUTH,
  });
  return logoutInfo;
};

export { login, getMe, logout };
