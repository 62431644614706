import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

import { store } from "./stores";
import AppRouter from "./routes";
import ThemeCustomization from "./styles/themes";

const App = () => {
  return (
    <ThemeCustomization>
      <Provider store={store}>
        <AppRouter />
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Provider>
    </ThemeCustomization>
  );
};

export default App;
