import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

import Item from "./Item";
import { TOP_BAR_HEIGHT } from "../../styles/config";
import { menus } from "./config";
import ROUTE from "../../constants/route";

const Menu = ({ openMiniSidebar, onClose }) => {
  const { isMasterRole, permissions = [] } = useSelector((state) => state.role);
  return (
    <Box
      display="flex"
      flexDirection="column"
      overflow="auto"
      height={`calc(100vh - ${TOP_BAR_HEIGHT}px)`}
      sx={{ overflowX: "hidden" }}
    >
      {menus
        .reduce((acc, menu) => {
          const { subMenu, path } = menu;
          if (isMasterRole || [ROUTE.DASHBOARD].includes(path)) {
            acc.push(menu);
          } else {
            if (!subMenu) {
              if (permissions.includes(path)) acc.push(menu);
            } else {
              const validSubMenu = subMenu.filter((sm) =>
                permissions.includes(sm.path)
              );
              if (validSubMenu.length) {
                acc.push({ ...menu, subMenu: validSubMenu });
              }
            }
          }

          return acc;
        }, [])
        .map((item) => (
          <Item
            key={item.label}
            item={item}
            isCollapse={openMiniSidebar}
            onClose={onClose}
          />
        ))}
    </Box>
  );
};

export default Menu;
