import React from "react";

import { PERMISSION_TYPE } from "../../constants";
import { COLOR, TRANSPARENT_COLOR } from "../../styles/color";
import { StyledPermissionType } from "./index.style";

const PermissionType = ({ type }) => {
  let color = "";
  let background = "";

  switch (type) {
    case PERMISSION_TYPE.API:
      color = COLOR.success.base;
      background = TRANSPARENT_COLOR.success;
      break;
    case PERMISSION_TYPE.MENU:
      color = COLOR.primary.base;
      background = TRANSPARENT_COLOR.info;
      break;
    default:
      break;
  }

  return (
    <StyledPermissionType color={color} background={background}>
      {type}
    </StyledPermissionType>
  );
};

export default PermissionType;
