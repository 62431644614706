import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Typography,
  Box,
  Tooltip,
  IconButton,
  Stack,
  Button,
} from "@mui/material";
import { Edit, Delete, AddCircle } from "@mui/icons-material";

import apis from "../../apis";
import { StyledGroupPermissionList } from "./index.style";
import GroupPermissionDialog from "./GroupPermissionDialog";
import Popup from "../../components/Popup";

const INIT_GROUP_PERMISSION = {
  name: "",
};

const GroupPermissionList = ({
  groupPermissionType,
  groupPermissions,
  permissions,
  selectedGroupPermission,
  setSelectedGroupPermission,
  onReload,
}) => {
  const { t } = useTranslation();
  const [isHoverItem, setIsHoverItem] = useState("");
  const [groupPermission, setGroupPermission] = useState(INIT_GROUP_PERMISSION);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState("create");
  const [openPopup, setOpenPopup] = useState(false);

  const handleOpenDialog = (type, object, event) => {
    if (event) event.stopPropagation();
    if (object) setGroupPermission(object);
    setDialogType(type);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setGroupPermission(INIT_GROUP_PERMISSION);
  };

  const handleOpenPopup = (gp, event) => {
    event.stopPropagation();
    setGroupPermission(gp);
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
    setGroupPermission(INIT_GROUP_PERMISSION);
  };

  const handleDeleteGroupPermission = async () => {
    try {
      await apis.groupPermission.deleteGroupPermission(groupPermission.id);
      toast.success(t("deleteGroupPermissionSuccess"));
      if (groupPermission.id === selectedGroupPermission.id)
        setSelectedGroupPermission({});
      onReload();
    } catch (error) {
      toast.error(t(error.message));
    }
  };

  const getCountPermission = (groupPermissionId) =>
    permissions.filter((p) => p.groupPermissionId === groupPermissionId).length;

  return (
    <>
      <StyledGroupPermissionList>
        {groupPermissions.map((item) => (
          <Box
            key={item.id}
            className={`item ${
              selectedGroupPermission.id === item.id && "item-selected"
            }`}
            onClick={() => setSelectedGroupPermission(item)}
            onMouseEnter={() => setIsHoverItem(item.id)}
            onMouseLeave={() => setIsHoverItem("")}
          >
            <Typography className="title">{item.name}</Typography>
            {isHoverItem === item.id ? (
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                <Tooltip title={t("edit")}>
                  <IconButton
                    size="small"
                    color="edit"
                    onClick={(e) => handleOpenDialog("update", item, e)}
                  >
                    <Edit fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("delete")}>
                  <IconButton
                    size="small"
                    color="error"
                    onClick={(e) => handleOpenPopup(item, e)}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            ) : (
              <Typography className="description">
                {getCountPermission(item.id)} {t("permission")}
              </Typography>
            )}
          </Box>
        ))}
        <Box m={2}>
          <Button
            variant="contained"
            fullWidth
            startIcon={<AddCircle />}
            onClick={() => handleOpenDialog("create")}
          >
            {t("addGroupPermission")}
          </Button>
        </Box>
      </StyledGroupPermissionList>
      <GroupPermissionDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        dialogType={dialogType}
        groupPermissionType={groupPermissionType}
        groupPermission={groupPermission}
        setGroupPermission={setGroupPermission}
        reload={onReload}
      />
      <Popup
        open={openPopup}
        onClose={handleClosePopup}
        onOk={handleDeleteGroupPermission}
        okMessage={t("accept")}
        title={t("areYouSureDeleteGroupPermission", {
          groupPermission: groupPermission.name,
        })}
      />
    </>
  );
};

export default GroupPermissionList;
