import { useTranslation } from "react-i18next";
import { Edit, LockOutlined, LockOpenOutlined } from "@mui/icons-material";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";

import Table from "../../components/Table";
import { COLOR } from "../../styles/color";
import { DIALOG_TYPE } from "../../constants";

const UserList = ({
  loading,
  paging,
  handleChangePage,
  users,
  handleOpenUserDialog,
  handleOpenChangeStatusPopup,
}) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("fullName"),
      align: "left",
      render: (row) => row.metadata?.name || row.name,
    },
    {
      title: t("phoneNumber"),
      align: "left",
      field: "phoneNumber",
    },
    {
      title: t("role"),
      align: "left",
      render: (row) => row.role.name,
    },
    {
      title: t("status"),
      align: "center",
      render: (row) => (
        <>
          {row.active ? (
            <Typography sx={{ color: COLOR.success.base }}>
              {t("active")}
            </Typography>
          ) : (
            <Typography sx={{ color: COLOR.error.base }}>
              {t("inactive")}
            </Typography>
          )}
        </>
      ),
    },
    {
      title: "...",
      align: "center",
      render: (row) => (
        <>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={t("edit")}>
              <span>
                <IconButton
                  onClick={() => handleOpenUserDialog(DIALOG_TYPE.UPDATE, row)}
                  disabled={!!row.role.isMasterRole}
                  color="edit"
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={row.active ? t("lock") : t("unlock")}>
              <span>
                <IconButton
                  onClick={() => handleOpenChangeStatusPopup(row)}
                  disabled={!!row.role.isMasterRole}
                  color={row.active ? "error" : "success"}
                >
                  {row.active ? <LockOutlined /> : <LockOpenOutlined />}
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        </>
      ),
    },
  ];

  return (
    <>
      <Table
        data={users}
        columns={columns}
        total={paging.total}
        page={paging.page}
        loading={loading}
        onChangePage={handleChangePage}
        showNumber={true}
      />
    </>
  );
};

export default UserList;
