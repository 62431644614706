import { SERVICE } from "../constants";
import api from "./api";

const getDiscounts = async (params) => {
  const res = await api({
    method: "GET",
    url: `/discount-codes`,
    params,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

const deleteDiscount = async (id) => {
  const res = await api({
    method: "DELETE",
    url: `/discount-codes/${id}`,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

const createDiscount = async (data) => {
  const res = await api({
    method: "POST",
    url: `/discount-codes`,
    data,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

const updateDiscount = async (id, data) => {
  const res = await api({
    method: "PUT",
    url: `/discount-codes/${id}`,
    data,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

export { getDiscounts, deleteDiscount, createDiscount, updateDiscount };
