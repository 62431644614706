import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import LoadingButton from "@mui/lab/LoadingButton";
import { AppBar, Box, TextField, Toolbar, Typography } from "@mui/material";
import { toast } from "react-toastify";

import Copyright from "../../components/Copyright";

import apis from "../../apis";
import { login } from "../../stores/authSlice";
import { StyledLogin } from "./index.style";
import MainCard from "../../components/MainCard";
import {
  validateRequired as checkRequired,
  validatePhoneNumber as checkPhoneNumber,
} from "../../utils/validate";

const INIT_CREDENTIAL = {
  phoneNumber: "",
  password: "",
};

const INIT_ERROR_CREDENTIAL = {
  phoneNumber: "",
  password: "",
};

const Login = () => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [credential, setCredential] = useState(INIT_CREDENTIAL);
  const [errorCredential, setErrorCredential] = useState(INIT_ERROR_CREDENTIAL);
  const dispatch = useDispatch();

  const validateRequired = (field) => {
    if (!checkRequired(credential[field])) {
      setErrorCredential((prev) => ({
        ...prev,
        [field]: t("fieldRequired"),
      }));
      return false;
    }
    return true;
  };

  const validatePhoneNumber = (field) => {
    if (!checkPhoneNumber(credential[field])) {
      setErrorCredential((prev) => ({
        ...prev,
        [field]: t("invalidPhoneNumber"),
      }));
      return false;
    }
    return true;
  };

  const validate = () =>
    validateRequired("phoneNumber") &&
    validatePhoneNumber("phoneNumber") &&
    validateRequired("password");

  const handleSubmit = async () => {
    if (!validate()) return;

    setLoading(true);
    try {
      const res = await apis.auth.login(credential);
      setLoading(false);
      dispatch(login(res.result.accessToken));
      toast.success(t("loginSuccess"));
    } catch (error) {
      setLoading(false);
      toast.error(t(error.message));
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  }

  const handleChangeCredential = (event, field) => {
    const value = event.target.value;
    setCredential((prev) => ({ ...prev, [field]: value }));
    setErrorCredential((prev) => ({ ...prev, [field]: false }));
  };

  return (
    <>
      <StyledLogin>
        <AppBar
          position="static"
          component="nav"
          color="white"
          elevation={0}
          sx={{ paddingY: "15px" }}
        >
          <Toolbar>
            <img
              src="/vela-logo.png"
              alt="vela"
              style={{ cursor: "pointer" }}
              height="60px"
            />
          </Toolbar>
        </AppBar>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="calc(100vh - 140px)"
        >
          <MainCard className="container">
            <Typography variant="h4" align="center" mb={3}>
              {t("login")}
            </Typography>
            <Box mb={2}>
              <Typography fontWeight={500} mb={1}>
                {t("phoneNumber")}
              </Typography>
              <TextField
                helperText={errorCredential.phoneNumber}
                error={!!errorCredential.phoneNumber}
                value={credential.phoneNumber}
                size="small"
                fullWidth
                onChange={(event) =>
                  handleChangeCredential(event, "phoneNumber")
                }
                onBlur={() => {
                  validateRequired("phoneNumber");
                  validatePhoneNumber("phoneNumber");
                }}
              />
            </Box>
            <Box mb={4}>
              <Typography fontWeight={500} mb={1}>
                {t("password")}
              </Typography>
              <TextField
                helperText={errorCredential.password}
                error={!!errorCredential.password}
                value={credential.password}
                size="small"
                fullWidth
                type="password"
                onChange={(event) => handleChangeCredential(event, "password")}
                onBlur={() => validateRequired("password")}
                onKeyDown={handleKeyDown}
              />
            </Box>
            <LoadingButton
              loading={loading}
              variant="contained"
              fullWidth
              onClick={handleSubmit}
            >
              {t("login")}
            </LoadingButton>
          </MainCard>
        </Box>
        <Box
          component="footer"
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="fixed"
          bottom={0}
          left={0}
          width="100%"
          height="50px"
        >
          <Copyright />
        </Box>
      </StyledLogin>
    </>
  );
};

export default Login;
