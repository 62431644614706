import { SERVICE } from "../constants";
import api from "./api";

const getOrders = async (params) => {
  const res = await api({
    method: "GET",
    url: `/orders`,
    params,
    source: SERVICE.VELA_AUTH,
  });
  return res;
};

export { getOrders };
