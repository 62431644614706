import React from "react";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

import { COLOR } from "../../styles/color";
import Language from "./Language";
import User from "./User";

const Header = ({
  onMobileSidebarToggle,
  openMiniSidebar,
  setOpenMiniSidebar,
}) => {
  return (
    <AppBar
      component="div"
      color="white"
      position="static"
      elevation={0}
      sx={{ borderBottom: `1px solid ${COLOR.black[8]}` }}
    >
      <Toolbar>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid sx={{ display: { md: "none", xs: "block" } }} item>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={onMobileSidebarToggle}
              edge="start"
            >
              <MenuIcon color="black" />
            </IconButton>
          </Grid>
          <Grid sx={{ display: { md: "block", sm: "none", xs: "none" } }} item>
            {openMiniSidebar ? (
              <IconButton
                onClick={() => setOpenMiniSidebar(false)}
                edge="start"
              >
                <FormatIndentIncreaseIcon color="black" />
              </IconButton>
            ) : (
              <IconButton onClick={() => setOpenMiniSidebar(true)} edge="start">
                <FormatIndentDecreaseIcon color="black" />
              </IconButton>
            )}
          </Grid>
          <Grid item xs></Grid>
          <Grid item>
            <Language />
          </Grid>
          <Grid item>
            <IconButton>
              <Badge badgeContent={4} color="primary">
                <NotificationsNoneOutlinedIcon color="black" />
              </Badge>
            </IconButton>
          </Grid>
          <Grid item>
            <User />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
