import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import queryString from "query-string";
import { toast } from "react-toastify";
import apis from "../../apis";
import MainCard from "../../components/MainCard";
import PageTitle from "../../components/PageTitle";
import { INITIAL_PAGING } from "../../constants";
import useSearchParams from "../../hooks/useSearchParams";
import OrderList from "./OrderList";

const Order = () => {
  const { t } = useTranslation();

  const location = useLocation();

  const [paging, setPaging] = useState(INITIAL_PAGING);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  const { addParams } = useSearchParams();

  const handleChangePage = (page) => {
    setPaging((prev) => ({ ...prev, page }));
    addParams({ page });
  };

  const fetchOrders = async ({ page }) => {
    setLoading(true);
    try {
      const condition = {
        page: page || paging.page,
        limit: paging.limit,
      };
      const { result } = await apis.order.getOrders(condition);
      setOrders(result.items);
      setPaging((prev) => ({ ...prev, total: result.total }));
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  useEffect(() => {
    const searchParams = queryString.parse(location.search);
    const { page = INITIAL_PAGING.page } = searchParams;
    setPaging((prev) => ({ ...prev, page: parseInt(page, 10) }));
    fetchOrders({ page: parseInt(page, 10) });
  }, [location.search]);

  return (
    <>
      <PageTitle title={t("orderManagement")} />
      <MainCard>
        <OrderList
          loading={loading}
          paging={paging}
          handleChangePage={handleChangePage}
          orders={orders}
        />
      </MainCard>
    </>
  );
};

export default Order;
