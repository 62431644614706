import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import apis from "../../apis";
import { COLOR } from "../../styles/color";
import { validateRequired as checkRequired } from "../../utils/validate";

const INIT_ERROR = {
  region: "",
};

const AsmConfig = ({ data, setData, setIsFetchQr }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const [error, setError] = useState(INIT_ERROR);

  const getRegions = async () => {
    setLoading(true);
    try {
      const res = await apis.region.getRegions();
      setRegions(res.result);
    } catch (error) {
      toast.error(t(error.message));
    }
    setLoading(false);
  };

  const handleChangeRegion = (newValue) => {
    setData((prev) => ({ ...prev, region: newValue }));
    setError((prev) => ({ ...prev, region: "" }));
  };

  const validateRequired = (field) => {
    if (!checkRequired(data[field])) {
      setError((prev) => ({
        ...prev,
        [field]: t("fieldRequired"),
      }));
      return false;
    }
    return true;
  };

  const validate = () => validateRequired("region");

  const handleCreateQr = () => {
    if (!validate()) return;
    setIsFetchQr(true);
  };

  useEffect(() => {
    getRegions();
  }, []);

  if (loading)
    return (
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        height="400px"
      >
        <CircularProgress />
      </Stack>
    );

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      spacing={2}
      height="400px"
    >
      <Box>
        <Typography fontWeight={500} mb={1}>
          {t("chooseRegion")} <span style={{ color: COLOR.error.base }}>*</span>
          :
        </Typography>
        <Autocomplete
          size="small"
          value={data.region || null}
          options={regions
            .filter((region) => !region.ownerId)
            .map((region) => region.name)}
          getOptionLabel={(option) => `${t("region")} ${option}`}
          filterSelectedOptions
          onChange={(event, newValue) => handleChangeRegion(newValue)}
          onBlur={() => validateRequired("region")}
          renderOption={(props, key) => (
            <MenuItem value={key} {...props}>
              {`${t("region")} ${key}`}
            </MenuItem>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!error.region}
              helperText={error.region}
              fullWidth
            />
          )}
        />
      </Box>
      <Stack justifyContent="center" alignItems="center">
        <Button variant="outlined" onClick={handleCreateQr}>
          {t("generateQr")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default AsmConfig;
